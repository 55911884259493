<template>
    <div
        :key="componentKey"
        class="container"
    >
        <div class="events-calendar-js">
            <div class="events-calendar">
                <button
                    class="btn btn--prev"
                    :disabled="offset < 0"
                    @click="onPrev"
                >
                    <i class="icofont-rounded-left"></i>
                </button>
                <div class="days">
                    <template
                        v-for="(item, i) in calendar"
                    >
                        <div
                            :key="i"
                            :class="item.classes"
                        >
                            <div
                                class="day-of-month"
                            >
                                <span class="day-of-month__label">
                                    {{ item.dayOfMonth }}
                                </span>
                            </div>
                            <div class="day-of-week">
                                <span>
                                    {{ item.dayOfWeek }}
                                </span>
                            </div>
                            <div
                                class="day-tooltip"
                                v-if="item.events"
                            >
                                <ul>
                                    <li
                                        v-for="event in item.events"
                                        :key="event.entity_id"
                                    >
                                        <a :href="event.slug | eventUri">
                                            <span class="day-tooltip__title">{{ event.title }}</span>
                                            <span class="day-tooltip__time">{{ event.begin | eventTime }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </template>
                </div>
                <button
                    class="btn btn--next"
                    @click="onNext"
                >
                    <i class="icofont-rounded-right"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import $http from 'axios'
import moment from 'moment'
/* eslint-disable */
import _ from 'lodash'

export default {
    name: 'App',
    filters: {
        eventTime(begin) {
            return moment(begin).format('HH:mm')
        },
        eventUri(slug) {
            return `/events/${slug}`
        }
    },
    data() {
        return {
            componentKey: 0,
            fakeEvent: {
                title: 'Event title'
            },
            offset: -1,
            events: []
        }
    },
    computed: {
        calendar() {
            const calendar = []

            moment.updateLocale('uk', {
                week: {
                    dow: 1
                }
            })

            const today = moment()
            const startDay = today.clone().startOf('week')
            const offset = this.offset

            let date = offset > 0
                ? startDay.clone().add(offset * 7, 'day')
                : startDay.clone().subtract(Math.abs(offset) * 7, 'day')

            const pushDay = (o, i) => {
                const day = date.add(1, 'day').clone()
                const dayOfMonth = day.format('DD')
                const dayOfWeek = day.format('ddd')
                const monthNumber = day.format('M')
                const monthName = day.format('MMMM')
                const year = day.format('Y')

                const lastItem = _.last(calendar)

                if ((lastItem && lastItem.monthNumber !== monthNumber)) {
                    calendar.push({
                        isMonth: true,
                        monthNumber,
                        monthName,
                        year,
                        classes: [
                            'day',
                            'day--as-month'
                        ],
                        dayOfMonth: day.format('MMM') + '.',
                        dayOfWeek: '-'
                    })
                }

                let events = _.filter(this.events, o => {
                    return moment(o.begin).format('Y-MM-DD') === day.format('Y-MM-DD')
                })

                calendar.push({
                    isMonth: false,
                    monthNumber,
                    monthName,
                    year,
                    classes: [
                        'day',
                        day.format('Y-M-D') === today.format('Y-M-D') ? 'day--today' : null,
                        _.isEmpty(events) ? 'day--is-empty' : null
                    ],
                    dayOfMonth: dayOfMonth,
                    dayOfWeek: dayOfWeek,
                    events: _.isEmpty(events) ? null : events
                })
            }

            Array(14).fill(0).map(pushDay)

            if (calendar.length === 14) {
                Array(1).fill(0).map(() => {
                    calendar.unshift({
                        classes: [
                            'day',
                            'day--as-month'
                        ],
                        dayOfMonth: date.format('MMM') + '.',
                        dayOfWeek: '-'
                    })
                })
            }

            return calendar
        },
        currentDay() {
            return moment()
        }
    },
    mounted() {
        $http.get(process.env.VUE_APP_WEB_API_URL + '/events')
            .then(response => {
                this.events = response.data.events
            })
            .catch(error => {
                console.log(error.response)
            })
    },
    methods: {
        onPrev() {
            this.componentKey++
            this.offset--
            this.offset = this.offset === 0
                ? -1
                : this.offset
        },
        onNext() {
            this.componentKey++
            this.offset++
            this.offset = this.offset === 0
                ? 1
                : this.offset
        }
    }
}
</script>

<style lang="scss">
.events-calendar-js {
    $height: 60px;

    display: flex;
    justify-content: center;
    max-width: 100%;
    // overflow: hidden;

    .events-calendar {
        @media screen and (min-width: 991px) {
            display: flex;
        }

        align-items: flex-start;
        // overflow-x: auto;
        padding: 8px;

        .days {
            @media screen and (min-width: 991px) {
                display: flex;
            }
        }

        .btn {
            height: $height;
            background-color: #f2f6f8;

            @media screen and (max-width: 990px) {
                width: 100%;

                &--prev {
                    margin-bottom: 12px;
                }

                &--next {
                    margin-top: 12px;
                }
            }


        }

        .day {
            display: inline-block;
            text-align: center;
            cursor: pointer;
            position: relative;

            .day-of-month {
                background-color: #ba9158;
                color: white;
                width: 52px;
                height: $height;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 4px;
                transition: background-color .3s;

                &__label {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }

            .day-of-week {
                opacity: 0.8;
            }

            .day-tooltip {
                position: absolute;
                display: none;
                left: 0;
                // bottom: 0;
                background-color: #f2f6f8;
                min-width: 200px;
                z-index: 99;

                &__title {
                    text-align: left;
                }

                &__time {
                    padding-left: 24px;
                }

                ul {
                    padding: 0;
                    margin: 0;
                    list-style: none;

                    li {
                        a {
                            padding: 12px;
                            display: flex;
                            font-size: 14px;

                            &:hover {
                                background-color: #9f3e2e;
                                color: white;
                            }
                        }
                    }
                }
            }

            &:hover, &:active {
                .day-of-month {
                    background-color: #9f3e2e;
                }

                .day-tooltip {
                    display: block;
                }

            }

            &--today {
                .day-of-month {
                    background-color: #9f3e2e;
                }
            }

            &--as-month {
                cursor: default;

                .day-of-month {
                    background-color: transparent;
                    color: inherit;


                    &__label {
                        background-color: transparent;
                        padding: 0;
                    }
                }

                .day-of-week {
                    visibility: hidden;
                }

                &:hover {
                    .day-of-month {
                        background-color: transparent;
                    }
                }
            }

            &--is-empty {
                cursor: default;

                .day-of-month {
                    background-color: #f2f6f8;
                    color: inherit;
                }

                &:hover {
                    .day-of-month {
                        background-color: #f2f6f8;
                    }
                }
            }
        }
    }
}
</style>
