import Vue from 'vue'
import App from './App.vue'

// import VTooltip from 'v-tooltip'
//
// Vue.use(VTooltip, {
//     themes: {
//         tooltip: {
//             placement: 'bottom',
//             html: true
//         }
//     }
// })
//
// import 'v-tooltip/dist/v-tooltip.css'

Vue.config.productionTip = false

new Vue({
    render: h => h(App)
}).$mount('#events-calendar-js')
